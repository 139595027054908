import cjAuth from '../auth/cjAuth'
import { FetchResponse } from '../types'

class FetchBuilder {
  private url?: string;
  private options: RequestInit;
  private cache: string;

  constructor(url?: string) {
    this.url = url ? url : `${process.env.REACT_APP_ADMIN_TOOL_API_URL}`;
    this.options = {};
    this.cache = "no-cache";
  }

  setPath(path: string): FetchBuilder {
    this.url = `${this.url}/${path}`;
    return this;
  }

  setBody(body: any): FetchBuilder {
    this.options.body = JSON.stringify(body);
    return this;
  }

  setMethod(method = 'GET'): FetchBuilder {
    this.options.method = method;
    return this;
  }

  setHeaders(contentType = 'text/xml'): FetchBuilder {
    this.options.headers = new Headers({'Content-Type': contentType });
    return this;
  }

  async build(): Promise<Response> {
    this.options = { ...this.options, headers: { ...this.options.headers, 'Authorization': `Bearer ${await cjAuth.getBearerToken()}`}};
    return await fetch(this.url!, this.options!);
  }
}

export default FetchBuilder;