import React, { CSSProperties, useContext, useState } from 'react'
import { Button } from '@cjdev-internal/visual-stack-x/Button'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { ToastElementOptions, useToast } from '@cjdev-internal/visual-stack-x/Toast'
import { AdminToolValues, FetchResponse, ToastOptions } from '../../../../types'
import AppContext from '../../../../context/AppContext'

const Notification = () => {
  const [mount, show, dismiss] = useToast();
  const { commissionValues: { toastOptions } }  = useContext<AdminToolValues>(AppContext);

  React.useEffect(() => {
    toastOptions && show(toastOptions)
    }, [toastOptions])

  return mount
}


export default Notification;