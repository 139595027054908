import React from 'react';
import { useState } from 'react'
import { FormModal, useIntl, User, useNavigate, useLocation } from '@platform/cj-platform-navigation'
import cjAuth from '../../auth/cjAuth'

const FormModalWrapper = ({user, children}: {user: User, children: React.ReactNode}) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const closeModal = () => {
      cjAuth.logout();
  }

  return (
    <FormModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onSubmit={() => console.log('form modal submitted')}
      submitText={'SAVE'}
      title={'Admin Tool'}
      user={user}
    >
      {children}
    </FormModal>
  )
}

export default FormModalWrapper;