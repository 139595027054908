import React from 'react';
import { Card } from '@cjdev-internal/visual-stack-x/Card'
import { CardSectionProps } from '../../types'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import '../styles.css'

const CardSection = ({href, title, description}: CardSectionProps) => {
  return(
    <Card className="vsx-card-clickable" href={href}>
      <Stack gap="medium">
        <Text weight="bold" size={18}>{title}</Text>
        <Text lineHeight={4} color="secondary" size={16}>{description}</Text>
      </Stack>
    </Card>
  )
}

export default CardSection;