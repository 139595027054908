
const headers = [
  {
    title: "Commission",
    info: " ➥ Commission Tool Wiki.",
    link: "https://wiki.cnvrmedia.net/pages/viewpage.action?spaceKey=CJE&title=Commission+Admin+Tool"
  }
];

const cards =  [
  {
    href: "/commission-request",
    title: "Correction Request",
    description: "Upload the commission file to be processed."
  },
  {
    href: "/commission-result",
    title: "Correction Result",
    description: "Get and download the commission results."
  },
];

export default {
  headers,
  cards
}