import React from 'react'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { AppIcon } from '@cjdev-internal/visual-stack-x/Icons'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { Header } from '@cjdev-internal/visual-stack-x/Panel'
import { HeaderSectionProps } from '../../types'

const HeaderSection = ({ title, info, link }: HeaderSectionProps): JSX.Element => {
  return(
    <Header>
      <Row gap="medium" align="center" paddingLeft="small">
        <AppIcon />
        <Text weight="bolder" color="success" size={20}>{title}</Text>
      </Row>
    </Header>
  )
}

export default HeaderSection;