import React from "react";
import { NavRoute, PlatformApp, PlatformDomains, Translations } from '@platform/cj-platform-navigation'
import createPlatformRoute from './createPlatformRoute'

type CommissionAppProps = {
    routes: NavRoute[],
    platformDomains: PlatformDomains
}

const createPlatformApp = ( translations: Translations, platformDomains: PlatformDomains, routes: NavRoute[]): JSX.Element  => {
    return <PlatformApp routes={routes as any} translations={translations} platformDomains={platformDomains} />
}

export default createPlatformApp;