import Commission from './Commission'

const commissionParser = (input: string) => {
    const commissions: Commission[] = [];
    const domparser = new DOMParser();
    const document = domparser.parseFromString(input, 'text/xml');
    const contents = document.querySelectorAll("Contents");
    for(const content of contents){
        const key = content?.querySelector("Key")?.innerHTML;
        const lastModified = content?.querySelector("LastModified")?.innerHTML;
        commissions.push(new Commission(key!, lastModified!))
    }
    return commissions;
}
export default commissionParser;
