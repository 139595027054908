import React from "react";
import { PageContent } from '@cjdev-internal/visual-stack-x/components/PageContent'
import { Body, Header, Panel } from '@cjdev-internal/visual-stack-x/Panel'
import { Grid } from '@cjdev-internal/visual-stack-x/Grid'
import HeaderSection from '../Header'
import CardSection from '../Card'
import { HomeProps } from '../../types'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import '../styles.css'
import { LINK } from '@cjdev-internal/visual-stack-x/Responsive'

const Home = ({headers, cards}: HomeProps ): JSX.Element => {
  return (
    <PageContent>
      <div className="commission-page">
        <div className="commission-page-content">
        {
          headers.map((header, idx) => (
            <Panel key={`${idx}-panel-home`}>
              <HeaderSection
                key={`${idx}-header-home`}
                title={header.title}
              />
              <Body>
                <Grid gap="large" columns="1fr 1fr">
                  {
                    cards?.map((card, idx) => (
                      <CardSection
                        key={`${idx}-card-home`}
                        href={card.href}
                        title={card.title}
                        description={card.description}
                      />
                    ))
                  }
                </Grid>
              </Body>
              <Row gap="medium" align="center" paddingLeft="large" paddingTop="small" paddingBottom="small">
                <a href={header.link} target="_blank" rel="noreferrer">
                  <i>{header.info}</i>
                </a>
              </Row>
            </Panel>
          ))
        }
        </div>
      </div>
    </PageContent>
  )
}

export default Home;