import React from 'react'
import { Form, FormGroup } from "@cjdev-internal/visual-stack-x/legacy/Form"
import { FormBuilderProps } from '../../types'

const FormBuilder = ({ FormContent }: FormBuilderProps) => {
  return(
    <Form vertical={true}>
      <FormGroup vertical={true}>
        <FormContent/>
      </FormGroup>
    </Form>

  )
}

export default FormBuilder;