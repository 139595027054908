import React, { useContext, useState } from 'react'
import {
  ActionMenuOptionDef,
  ColumnDef,
  DataGrid,
  useGridData
} from '@cjdev-internal/visual-stack-x/DataGrid'
import { ExpandingInputButton } from '@cjdev-internal/visual-stack-x/ExpandingInputButton'
import { TopPanelProps } from '@cjdev-internal/visual-stack-x/components/DataGrid/TopPanel'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import Commission from '../Commission'
import AppContext from '../../../../context/AppContext'
import commissionParser from '../commissionParser'
import Loading from '../../../Loading'
import { AdminToolValues, FetchProps, FetchResponse } from '../../../../types'
import FetchBuilder from '../../../../fetch/FetchBuilder'
import FileDisplayed from '../FileDisplayed'

const FileFetcher = () => {
  const { handleAuthorization, commissionValues: { commissionResponse, handleCommissionResponse } }  = useContext<AdminToolValues>(AppContext);

  React.useEffect(() => {
    (async () => {
      const response = await new FetchBuilder().build();
      if(response?.status === 403){
        handleAuthorization();
      }
      const commissionsDOM = await response.text();
      const commissions = commissionParser(commissionsDOM)
      handleCommissionResponse(commissions!);
    })();
  }, [])


  return !commissionResponse ? <Loading animated={true}/> : <FileDisplayed commissionData={commissionResponse}/>
}
export default FileFetcher;