import React from "react";
import { AdminToolValues, CommissionValues, FetchResponse, ToastOptions } from '../types'
import { User } from '@platform/cj-platform-navigation'
import Commission from '../components/Tools/Commission/Commission'

const defaultCommissionValues: CommissionValues = {
  commissionFile: null,
  handleClearInputFile: () => null,
  handleFileUpload: (file: File | null) => null,
  dataFetch: undefined,
  handleDataFetch: (data: FetchResponse) => null,
  handleSetToastOptions:  (data: FetchResponse) => null,
  toastOptions: undefined,
  handleClearToastOptions: () => undefined,
  commissionResponse: undefined,
  handleCommissionResponse: (commissionResponse: Commission[]) => null
}

const defaultValue: AdminToolValues = {
  //cjUser: null,
  isUnauthorized: false,
  handleAuthorization: () => null,
  commissionValues: defaultCommissionValues

}

const AppContext = React.createContext(defaultValue);

export default AppContext;