const authConfig = () => {
    const prod = {
        "clientId": process.env.REACT_APP_OAUTH_CLIENT_ID,
        "redirectUri": process.env.REACT_APP_OAUTH_REDIRECT_URI
    }

    const lab = {
        "clientId": process.env.REACT_APP_OAUTH_CLIENT_ID,
        "redirectUri": process.env.REACT_APP_OAUTH_REDIRECT_URI
    }

    const local = {
        "clientId": process.env.REACT_APP_OAUTH_CLIENT_ID,
        "redirectUri": process.env.REACT_APP_OAUTH_REDIRECT_URI
    }

    const hostname = window.location.hostname;

    if (hostname.includes("local")) {
        return local;
    }

    if (hostname.includes("fintech.dev.cj.com")) {
        return lab;
    }

    if (hostname.includes('fintech.cj.com')) {
      return prod
    }
}

export default authConfig