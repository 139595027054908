import 'regenerator-runtime/runtime'
import {
  initAuth,
  getBearerToken,
  getUserId,
  isLoggedIn,
  logout
} from '@platform/platform-auth'

export default {
  initAuth,
  getBearerToken,
  getUserId,
  isLoggedIn,
  logout
}
