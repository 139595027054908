import React from 'react'
import * as ReactDOMClient from 'react-dom/client';

import { initAuth } from '@platform/platform-auth'
import {authConfig} from './auth'
import Dependencies from './Dependencies'

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);

initAuth(authConfig()).then(async () => {
  root.render(<Dependencies/>)
})