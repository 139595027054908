import React from 'react'
import { ErrorPage } from '@cjdev-internal/visual-stack-x/ErrorPage'
import { PageContent } from '@cjdev-internal/visual-stack-x/components/PageContent'

const Unauthorized = () => {

  return (
    <PageContent>
      <ErrorPage
        errorCode={403}
      />
    </PageContent>
  )
}

export default Unauthorized;