import React, { useContext, useState } from 'react'
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import { setRequestInit, fetcher } from '../../../../fetch';
import { AdminToolValues, FetchResponse, FileSenderProps, ToastOptions } from '../../../../types'
import AppContext from '../../../../context/AppContext'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import FetchBuilder from '../../../../fetch/FetchBuilder'

const FileSender = (): JSX.Element => {
  const [isReadyToSend, setIsReadyToSend] = useState<boolean>(false);

  const { handleAuthorization, commissionValues: { commissionFile, handleClearInputFile, dataFetch, handleDataFetch, handleSetToastOptions } }  = useContext<AdminToolValues>(AppContext);

  const handleClick = () => {
    setIsReadyToSend(!isReadyToSend);
    console.log("CLICK");
  }

  React.useEffect( () => {
    (async () => {
      if(commissionFile && isReadyToSend) {
        const body = await commissionFile.text();
        const init = await setRequestInit(body);
        const data = await fetcher(`/${commissionFile.name}`, init);
        if(data?.status === 403){
          handleAuthorization();
        }
        handleDataFetch(data!);
        handleSetToastOptions(data!);
        handleClearInputFile();
      }
    })();
  },[commissionFile, isReadyToSend])

  return(
    <div className="commission-tool-send">
      <Row gap="small" padding="large">
        <Button
          type="primary"
          disabled={!commissionFile}
          onClick={handleClick}>
            Send
        </Button>
      </Row>
    </div>
  )
}

export default FileSender;
