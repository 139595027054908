import React from 'react'
import { NavRoute, TitleMessage, User } from '@platform/cj-platform-navigation'
import { PageContent } from '@cjdev-internal/visual-stack-x/components/PageContent'

const createPlatformRoute = (path: string, titleMessage: TitleMessage, Page: React.ElementType, Form: React.ElementType): NavRoute => {
  return ({
    path,
    titleMessage,
    Content: ({ user }: { user: User }) => {
      return(
        <PageContent>
          <Form user={user}>
              <Page/>
          </Form>
        </PageContent>
        )
    }
  })
}
export default createPlatformRoute;



