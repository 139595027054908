import React, { useContext } from 'react'
import { PageContent } from '@cjdev-internal/visual-stack-x/components/PageContent'
import { Body, Footer, Header, Panel } from '@cjdev-internal/visual-stack-x/Panel'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { AdminToolValues, PageProps } from '../../types'
import Unauthorized from '../403'
import AppContext from '../../context/AppContext'
import '../styles.css';

const Page = ({ Bread, title, BodyContent, FooterContent, PanelContent }: PageProps) => {

  const { isUnauthorized }  = useContext<AdminToolValues>(AppContext);
  const FooterContentValid = () => FooterContent ? <FooterContent/> : <></>;
  const PanelContentValid = () => PanelContent ? <PanelContent/> : <></>;

  return isUnauthorized ? <Unauthorized/> : (
    <PageContent>
      <div className="commission-page">
        <div className="commission-page-content">
          <Header>
            <Bread/>
          </Header>
          <Panel>
            <Header>
              <Text color="secondary">{title}</Text>
            </Header>
            <Body>
              <BodyContent/>
            </Body>
            <Footer>
              <FooterContentValid/>
            </Footer>
            <PanelContentValid/>
          </Panel>
        </div>
      </div>
    </PageContent>
  )
}

export default Page;