import "regenerator-runtime/runtime";
import React, { useState } from 'react'
import Loading from "../components/Loading";
import AppContext from "./AppContext";
import {
    AdminToolValues,
    AppProviderProps,
    CommissionValues,
    FetchResponse,
    ICommissionUser,
    ToastOptions
} from '../types'
import { User } from '@platform/cj-platform-navigation'
import Commission from '../components/Tools/Commission/Commission'

const AppProvider = ({children}: AppProviderProps ): JSX.Element => {
    const [commissionFile, setCommissionFile] = useState<File | null>(null);
    const [dataFetch, setDataFetch] = useState<FetchResponse | undefined>(undefined);
    const [toastOptions, setToastOptions] = useState<ToastOptions | undefined>(undefined);
    const [commissionResponse, setCommissionResponse] = useState<Commission[] | undefined>(undefined);
    const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false);


    const handleClearInputFile = () => {
        setCommissionFile(null);
    }

    const handleFileUpload = (file: File) => {
        console.log("File Charged")
        setCommissionFile(file);
    };

    const handleDataFetch = (data: FetchResponse) => setDataFetch(data);

    const handleClearToastOptions = () => setToastOptions(undefined);

    const handleSetToastOptions = (data: FetchResponse) => {
        const opt: ToastOptions = {
            message: data?.message,
            type: data?.status === 200 ? "success" : "warning",
            contentStyle: { textAlign: "center", alignContent: "center", alignItems: "center", alignSelf: "center", fontSize: "18px", minWidth: "500px", minHeight: "35px" },
            dismissible: true,
            duration: 4000
        }
        setToastOptions(opt);
    }

    const handleAuthorization = () => setIsUnauthorized(!isUnauthorized);

    const handleCommissionResponse = (data: Commission[]) => setCommissionResponse(data);

    const commissionValues: CommissionValues = {
        commissionFile, // fileUploader, fileSender
        handleClearInputFile, // fileUploader
        handleFileUpload, // fileUploader
        dataFetch, // fileSender
        handleDataFetch, // fileSender
        handleSetToastOptions, // notification
        toastOptions,
        handleClearToastOptions, // notification
        commissionResponse, // dataGridResponse
        handleCommissionResponse // dataGridResponse
    }

    const value: AdminToolValues = {
        isUnauthorized,
        handleAuthorization,
        commissionValues
    }

    return <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
}

export default AppProvider;