import React, { useContext } from 'react';
import { Field } from '@cjdev-internal/visual-stack-x/Field'
import AppContext from '../../../../context/AppContext';
import { AdminToolValues } from '../../../../types';
import { messages } from '../../../../messages';
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { SingleFileUpload } from "@cjdev-internal/visual-stack-x/SingleFileUpload"
import './styles.css'

const FileUploader: React.FC = () => {
  const { commissionValues: { handleFileUpload, handleClearToastOptions } }  = useContext<AdminToolValues>(AppContext);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    console.log(event.target.value)
    handleFileUpload(file);
  };

  return (
    <Stack gap="medium" padding="large">
        <Field
          label={messages.labelContent.defaultMessage}
        />
        <SingleFileUpload
          className='commission-textfield'
          name='commissionCorrectionFile'
          type='file'
          onChange={handleFileInput}
          onClick={handleClearToastOptions}
          maxLength={1024}
        />
    </Stack>
  );
};

export default FileUploader;


